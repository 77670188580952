.btn-login {
  position: fixed;
  top: 2px;
  right: 2px;
}

.fullhd {
  height: 100vh;
}
.centro {
  margin: auto auto;
}

.divmenu1 {
  border-radius: 12px;
  box-shadow: -5px -5px 8px rgba(0, 0, 0, 0.453);
}

.efectosol {
  box-shadow: -5px -5px 8px rgba(0, 0, 0, 0.453);
}

.bg-ariztia {
  background-color: #DD271C;
}
.rounded-1 {
  border-radius: 5px;
}

.rounded-2 {
  border-radius: 10px;
}

.rounded-3 {
  border-radius: 15px;
}

.rounded-4 {
  border-radius: 20px;
}

.rounded-5 {
  border-radius: 25px;
}
.bg-shadow {
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.501);
}
.cursor {
  cursor: pointer;
}

.disabled-link {
  pointer-events: none;
}

.outder {
  height: 90vh;
  width: 100%;
  /*IMPORTANTE*/
  display: flex;
  justify-content: center;
  align-items: center;
}
.outdermini {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.iner {
  min-width: 75%;
  border-radius: 10px;
  padding: 25px 5px;
}